import { makeAutoObservable, observable, autorun } from "mobx";

import UiStore from "./ui";
import VariablesStore from "./variables";
import CustomFormStore from "./customForm";
import UserStore from "./user";
import NavigationStore from "./navigation";

export class RootStore {
  ui: UiStore;
  variables: VariablesStore;
  customForm: CustomFormStore;
  user: UserStore;
  navigation: NavigationStore;

  constructor() {
    this.ui = new UiStore(this);
    this.variables = new VariablesStore(this);
    this.customForm = new CustomFormStore(this);
    this.user = new UserStore(this);
    this.navigation = new NavigationStore(this);

    makeAutoObservable(this, {
      variables: observable.deep,
    });
  }

  reset = () => {
    this.ui = new UiStore(this);
    this.variables = new VariablesStore(this);
    this.customForm = new CustomFormStore(this);
    this.user = new UserStore(this);
    this.navigation = new NavigationStore(this);
  };
}

const store = new RootStore();

autorun(() => {
  if (
    store.navigation.steps.length > 0 &&
    !store.navigation.haveStep("DOCUMENT_ISSUED_COUNTRY") &&
    store.variables.document.documentIssuedCountry === null
  ) {
    store.variables.document.setDocumentIssuedCountry("BR");
    store.variables.document.setSelectedDocumentType("");
  }
});

export default store;
