import { makeAutoObservable } from "mobx";
import { RootStore } from "..";

export interface FormData {
  [formKey: string]: {
    [fieldKey: string]: any;
  };
}

export default class CustomFormStore {
  formData: FormData = {};

  constructor(protected rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setFormData = (formKey: string, fieldKey: string, value: any) => {
    if (!this.formData[formKey]) {
      this.formData[formKey] = {};
    }

    this.formData[formKey][fieldKey] = value;
  };

  getFormData = (formKey: string, fieldKey: string) => {
    return this.formData[formKey]?.[fieldKey];
  };

  getForm = (formKey: string) => {
    return this.formData[formKey];
  };
}
