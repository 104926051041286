import { getCountryCallingCode, AsYouType, isValidNumber, parseNumber, ParsedNumber } from "libphonenumber-js";
import { Countries } from "../../types";
import store from "@store/index";

export default function usePhoneFormatter() {
  const formatPhone = (phone?: string) => {
    if (!phone) return "";

    const formattedPhone = new AsYouType().input(phone);

    return formattedPhone;
  };

  const phoneValidation = (phone?: string) => {
    if (!phone) return false;

    const phoneNumber: any = parseNumber(phone);

    const isBrazilianNumber = phoneNumber?.country === "BR";
    const isPhoneNumberLengthInvalid = phoneNumber?.phone?.length < 10;

    if (isBrazilianNumber && isPhoneNumberLengthInvalid) return false;

    if (!!phoneNumber) {
      return isValidNumber(phoneNumber as ParsedNumber);
    }

    return false;
  };

  const phoneValidationPhoneInput2 = (phone?: string, required?: boolean) => {
    if (!phone) return false;

    if (phone?.length > 2 || required) {
      const phoneNumber: any = parseNumber(`+${phone}`);

      const isBrazilianNumber = phoneNumber?.country === "BR";
      const isPhoneNumberLengthInvalid = phoneNumber?.phone?.length < 10;

      if (isBrazilianNumber && isPhoneNumberLengthInvalid) return false;

      if (!!phoneNumber) {
        return isValidNumber(phoneNumber as ParsedNumber);
      }
      return false;
    } else {
      return true;
    }
  };

  const getDefaultCountryCallingCode = () => {
    let country: Countries | null | undefined = null;

    if (store.navigation.haveStep("COMPANY_LOCATION")) {
      country = store.variables.company.companyCountry;
    } else {
      country = store.variables.document.documentIssuedCountry;
    }

    let countryCallingCode = "";
    if (country) countryCallingCode = getCountryCallingCode(country);

    return `+${countryCallingCode} `;
  };

  return {
    phoneValidation,
    phoneValidationPhoneInput2,
    formatPhone,
    getDefaultCountryCallingCode,
  };
}
