import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Router, Switch, Route, Redirect, matchPath } from "react-router-dom";
import { createBrowserHistory } from "history";

import { AnalyticsProvider } from "@contexts/Analytics";
import { RudderAnalyticsProvider } from "@contexts/RudderAnalytics/RudderAnalytics";
import { TemplateDataContextProvider } from "./hooks/useTemplateData";

import "./i18n";
import "./styles/main.less";

const history = createBrowserHistory();
var pjson = require("../package.json");

const routes = [{ path: "/:token" }, { path: "/" }];

const container = document.getElementById("root");
const root = createRoot(container);

const App = React.lazy(() => import("./App"));

root.render(
  <Suspense fallback={<></>}>
    <Router history={history}>
      <Switch>
        <Route path={["/:token", "/"]}>
          <TemplateDataContextProvider>
            <AnalyticsProvider>
              <RudderAnalyticsProvider>
                <App />
              </RudderAnalyticsProvider>
            </AnalyticsProvider>
          </TemplateDataContextProvider>
        </Route>
        <Route path="/:token/*" render={(props) => <Redirect to={`/${props.match.params.token}`} />} />
      </Switch>
    </Router>
  </Suspense>,
);
