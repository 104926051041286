export enum RudderStackStepViwedActions {
  NEXT = "next",
  PREVIOUS = "previous",
}
export enum RudderStackPermissionUpdateStatus {
  ALLOWED = "allowed",
  DENIED = "denied",
  DISMISSED = "dismissed",
}
export enum RudderStackEvents {
  ONBOARDING_STARTED = "Onboarding Started",
  STEP_VIEWED = "Step Viewed",
  LOCATION_PERMISSION_UPDATED = "Location Permission Updated",
  CAMERA_PERMISSION_UPDATED = "Camera Permission Updated",
  PAGE_EVENT_ONBOARDING = "Onboarding",
}
