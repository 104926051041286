const defaultValues = {
  analytics: "",
  analyticsTrackingId: "",
  initializeAnalytics: () => {},
  analyticsEventsEmitted: [],
  setAnalyticsEventsEmitted: () => {},
  analyticsCheckpointsEmitted: [],
  setAnalyticsCheckpointsEmitted: () => {},
  sessionId: "",
  isAnalyticsInitializing: false,
};
Object.freeze(defaultValues);

export default defaultValues;
