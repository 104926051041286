import UserStore from ".";

import { makeAutoObservable } from "mobx";
import { Logger } from "@utils/logging";

export default class UserMetadataStore {
  userAgent = "";
  userAgentData: UADataValues | undefined = undefined;
  // Available browser names https://github.com/lancedikson/bowser/blob/master/src/constants.js
  browserName = "";
  browserVersion = "";
  location: GeolocationCoordinates | null = null;

  constructor(private userStore: UserStore) {
    makeAutoObservable(this);
  }

  setUserAgent = (userAgent: string) => {
    this.userAgent = userAgent;
  };

  setUserAgentData = async (userAgentData: NavigatorUAData) => {
    try {
      this.userAgentData = await userAgentData?.getHighEntropyValues([
        "architecture",
        "bitness",
        "brands",
        "formFactor",
        "mobile",
        "model",
        "platform",
        "platformVersion",
        "uaFullVersion",
        "fullVersionList",
        "wow64",
      ]);
    } catch (err) {
      Logger.warn("Could not set user agent data", err);
    }
  };

  setBrowserName = (browserName: string) => {
    this.browserName = browserName;
  };

  setBrowserVersion = (browserVersion: string) => {
    this.browserVersion = browserVersion;
  };

  setLocation = (location: GeolocationPosition) => {
    const parsedObject = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      altitude: location.coords.altitude,
      accuracy: location.coords.accuracy,
      altitudeAccuracy: location.coords.altitudeAccuracy,
      heading: location.coords.heading,
      speed: location.coords.speed,
    };
    this.location = parsedObject;
  };
}
