import { RootStore } from "..";
import { makeAutoObservable } from "mobx";

import LoadingStore from "./loading";
export default class UiStore {
  loading: LoadingStore;

  constructor(rootStore: RootStore) {
    this.loading = new LoadingStore(rootStore);

    makeAutoObservable(this);
  }
}
