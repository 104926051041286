import { getFirstWord, Regex, toTitleCase } from "./formatting";
import { Logger } from "./logging";

const reactStringReplace = require("react-string-replace");

export const variableModifiers = {
  UPPER: "upper",
  LOWER: "lower",
  TITLE: "title",
  FIRST_WORD: "first-word",
};

/** Finds and replaces variables with a specified transformation.
 * @argument find can be a string or regex
 * @argument transformation has to be a function returning the transformed find (React component)
 */
export function replaceVariables(str = "", find, transformation) {
  if (!find) {
    return str;
  } else if (!transformation) {
    Logger.console("If a find is informed, a transformation has also to be informed.");
    return str;
  }

  return reactStringReplace(str, find, transformation);
}

/** Removes brackets (variable delimiters) from string */
export function cleanVariable(str = "") {
  return str.replace(/(\{|\})/gm, "").replace(Regex.variableModifier, "");
}

/** Applies modifiers to a string */
export function applyModifiers(str = "", modifiers = []) {
  modifiers?.forEach((modifier) => {
    modifier = modifier?.replace(/:/gi, "");
    switch (modifier) {
      case variableModifiers.LOWER:
        str = str.toLowerCase();
        break;
      case variableModifiers.UPPER:
        str = str.toUpperCase();
        break;
      case variableModifiers.TITLE:
        str = toTitleCase(str);
        break;
      case variableModifiers.FIRST_WORD:
        str = getFirstWord(str);
        break;
      default:
        break;
    }
  });
  return str;
}
