import { Logger } from "@utils/logging";
import { RootStore } from "..";
import { makeAutoObservable } from "mobx";

interface StartLoadingOptions {
  heading?: string;
  subheading?: string;
  isPageLoading?: boolean;
}

export default class LoadingStore {
  isLoading = false;
  heading = "";
  subheading = "";
  isPageLoading = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  start = (options?: StartLoadingOptions) => {
    Logger.console("LoadingStore.start", options);
    this.isLoading = true;
    this.heading = options?.heading ?? "";
    this.subheading = options?.subheading ?? "";
    this.isPageLoading = options?.isPageLoading ?? false;
  };

  stop = () => {
    Logger.console("LoadingStore.stop");
    if (!this.isLoading) return;
    this.isLoading = false;
    this.heading = "";
    this.subheading = "";
    this.isPageLoading = false;
  };
}
