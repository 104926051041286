// Parse a step name to get its name and index. Ex.: "WELCOME-0" = ["WELCOME", 0]
export function parseStep(name = "") {
  let [stepName, stepIndex] = name.split("-");
  stepIndex = stepIndex && parseInt(stepIndex);
  return [stepName, stepIndex];
}

// Returns the step name with its correct index
export function indexStep(steps = [], name = "") {
  if (!isNaN(parseStep(name)[1])) return name;
  let repeatedSteps = steps.filter((s) => parseStep(s.name)[0] === name) || [];
  let nameWithIndex = `${name}-${repeatedSteps.length}`;
  return nameWithIndex;
}
