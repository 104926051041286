import { createContext, useState, useEffect, useContext, useCallback } from "react";
import Bowser from "bowser";
import { Sdk, AnalyticsSources, SourceProject, fingerprint } from "@combateafraude/analytics-sdk";
import { Logger } from "@utils/logging";
import { v4 as uuidV4 } from "uuid";
import store from "@store/index";

import defaultValues from "./defaultValues";

/**
 * Context analytics SDK, the analytics SDK is used to send metrics
 * from onboarding to the API, these metrics are used both for debugging and
 * to display onboarding data to the end customer
 */

export const AnalyticsContext = createContext(defaultValues);

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(defaultValues.sessionId);
  const [analytics, setAnalytics] = useState(defaultValues.analytics);
  const [analyticsTrackingId, setAnalyticsTrackingId] = useState(defaultValues.analyticsTrackingId);
  const [isAnalyticsInitializing, setIsAnalyticsInitializing] = useState(defaultValues.isAnalyticsInitializing);

  useEffect(() => {
    const sessionId = uuidV4();
    setSessionId(sessionId);
  }, []);

  // Control to not emit same events
  const [analyticsEventsEmitted, setAnalyticsEventsEmitted] = useState([]);

  const getDeviceInfoFunction = useCallback(async () => {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const deviceInfo = {
      ...bowser.parsedResult,
      userAgent: bowser._ua,
    };

    return deviceInfo;
  }, []);

  const initializeAnalytics = async () => {
    try {
      setIsAnalyticsInitializing(true);
      const sdk = new Sdk({
        source: AnalyticsSources.Onboarding,
        getDeviceInfoFunction,
        stage: process.env.REACT_APP_ENV,
        // Old options
        sourceProject: SourceProject.Onboardings,
        getDeviceInfoFunctionOld: () => fingerprint.getBrowserFingerprint(),
      });
      await sdk.initialize();
      setAnalytics(sdk);
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't initialize Analytics SDK.",
      });
      setAnalytics(null);
    } finally {
      setIsAnalyticsInitializing(false);
    }
  };

  useEffect(() => {
    initializeAnalytics();
  }, []);

  useEffect(() => {
    const configureAnalytics = async () => {
      if (!!analytics) {
        try {
          const analyticsInfo = await analytics?.getAnalyticsInfo();
          setAnalyticsTrackingId(analyticsInfo.trackingIdFromServer);
        } catch (error) {
          Logger.error(error, {
            message: "Couldn't get Analytics Tracking Id.",
          });
          setAnalyticsTrackingId(null);
        }
      }
    };

    configureAnalytics();
  }, [analytics]);

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        analyticsTrackingId,
        initializeAnalytics,
        analyticsEventsEmitted,
        setAnalyticsEventsEmitted,
        sessionId,
        isAnalyticsInitializing,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
