import { Logger } from "@utils/logging";
import { useRudderAnalyticsContext } from "./RudderAnalytics";
import { RudderStackEvents, RudderStackStepViwedActions, RudderStackPermissionUpdateStatus } from "./stepsAndEvents";

interface RudderAnalyticsFunctions {
  setRudderTrackData: (step: RudderStackEvents, parameters: any) => void;
  setRudderPageData: (page: RudderStackEvents) => void;
  RudderStackEvents: typeof RudderStackEvents;
  RudderStackStepViwedActions: typeof RudderStackStepViwedActions;
  RudderStackPermissionUpdateStatus: typeof RudderStackPermissionUpdateStatus;
}

const RudderAnalyticsHook = (): RudderAnalyticsFunctions => {
  const { rudderAnalyticsSdk } = useRudderAnalyticsContext();

  const setRudderTrackData = (step: RudderStackEvents, parameters: any): void => {
    try {
      rudderAnalyticsSdk?.track(step, parameters);
    } catch (error) {
      Logger.error(error, {
        message: "Error sending event to Rudder SDK",
      });
    }
  };

  const setRudderPageData = (page: RudderStackEvents): void => {
    try {
      rudderAnalyticsSdk?.page(page);
    } catch (error) {
      Logger.error(error, {
        message: "Error sending page to Rudder SDK",
      });
    }
  };

  return {
    setRudderTrackData,
    RudderStackEvents,
    RudderStackStepViwedActions,
    RudderStackPermissionUpdateStatus,
    setRudderPageData,
  };
};

export default RudderAnalyticsHook;
