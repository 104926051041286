import { RootStore } from "..";
import { makeAutoObservable } from "mobx";

import UserMetadataStore from "./metadata";

export interface StepTerm {
  name: string;
  label: string;
  id: string;
  required?: boolean;
}

interface Terms extends StepTerm {
  link: string;
  datetime: string;
  userAgent: string;
  required?: boolean;
}

export default class UserStore {
  metadata: UserMetadataStore;

  ip: string | null = null;
  acceptedTerms: { [key: string]: Terms } = {};
  readBrowserCompatibilityTerms: boolean;

  constructor(private rootStore: RootStore) {
    this.metadata = new UserMetadataStore(this);

    this.readBrowserCompatibilityTerms = !!localStorage.getItem("userReadBrowserCompatibilityTerms");

    makeAutoObservable(this);
  }

  setReadBrowserCompatibilityTerms = () => {
    this.readBrowserCompatibilityTerms = true;
    localStorage.setItem("userReadBrowserCompatibilityTerms", "true");
  };

  addAcceptanceTerm = (term: StepTerm) => {
    if (!this.acceptedTerms[term.id]) {
      const pattern = /\((.*?)\)/g;
      const termLinks = term?.label?.match(pattern) ?? [];

      let link = "";

      if (termLinks.length > 0) {
        termLinks.forEach((link, index) => (termLinks[index] = link.replace(pattern, "$1")));
        link = termLinks.join(", ");
      }

      this.acceptedTerms[term.id] = {
        ...term,
        datetime: new Date().toISOString(),
        link,
        userAgent: this.metadata.userAgent,
      };
    }
  };

  removeAcceptanceTerm = (id: string) => {
    delete this.acceptedTerms[id];
  };
}

export const buildTermIdGenerator = (prefix: string) => (term: StepTerm, index: number) =>
  `${prefix}_${index}_${term.name}`;
