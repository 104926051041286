import React, { createContext, useContext, useEffect, useState } from "react";
import { DEFAULT_VALUES } from "./defaultValues";
import { RudderAnalytics } from "@rudderstack/analytics-js";
import { Logger } from "@utils/logging";

interface Props {
  children: React.ReactNode;
}
interface RudderAnalyticsContextValue {
  rudderAnalyticsSdk?: RudderAnalytics;
}

const RudderAnalyticsContext = createContext<RudderAnalyticsContextValue>(
  DEFAULT_VALUES as RudderAnalyticsContextValue,
);
export const useRudderAnalyticsContext = () => useContext(RudderAnalyticsContext);

export const RudderAnalyticsProvider = (props: Props) => {
  const [rudderAnalyticsSdk, setRudderAnalyticsSdk] = useState<RudderAnalytics | undefined>(undefined);

  useEffect(() => {
    try {
      if (!rudderAnalyticsSdk) {
        const rudderAnalyticsInstance: RudderAnalytics = new RudderAnalytics();

        rudderAnalyticsInstance.load(
          process.env.REACT_APP_RUDDERSTACK_WRITE_KEY || "",
          process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL || "",
        );

        rudderAnalyticsInstance.ready(() => {
          Logger.info("Rudder SDK ready!");
        });

        setRudderAnalyticsSdk(rudderAnalyticsInstance);
      }
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't initialize Rudder Analytics SDK.",
      });
    }
  }, [rudderAnalyticsSdk]);

  return (
    <RudderAnalyticsContext.Provider value={{ rudderAnalyticsSdk }}>{props.children}</RudderAnalyticsContext.Provider>
  );
};
