import { makeAutoObservable } from "mobx";

import VariablesStore from ".";

export default class PFPFVariablesStore {
  PFPFCpf = "";
  PFPFName = "";
  PFPFEmail = "";
  PFPFPhoneNumber = "";

  constructor(private variablesStore: VariablesStore) {
    makeAutoObservable(this);
  }

  setPFPFCpf = (PFPFCpf: string) => {
    this.PFPFCpf = PFPFCpf;
  };

  setPFPFName = (PFPFName: string) => {
    this.PFPFName = PFPFName;
  };

  setPFPFEmail = (PFPFEmail: string) => {
    this.PFPFEmail = PFPFEmail;
  };

  setPFPFPhoneNumber = (PFPFPhoneNumber: string) => {
    this.PFPFPhoneNumber = PFPFPhoneNumber;
  };
}
