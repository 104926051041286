import { applyModifiers, cleanVariable, replaceVariables } from "@utils/markdown";

/** Collection of regex */
export const Regex = {
  cpf: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/,
  cnpj: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/,
  // cep: {
  //   "pt-BR": /[0-9]{2}\.?[0-9]{3}-?[0-9]{3}/,
  //   "en-US": /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  // },
  cep: /[0-9]{2}\.?[0-9]{3}-?[0-9]{3}/,
  // date: {
  //   "pt-BR": /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
  //   "en-US": /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  // },
  date: /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
  alphabetical: /[^A-Za-záàâãéèêíìïóôõöúùçñÁÀÂÃÉÈÍÌÏÓÔÕÖÚÙÇÑ´`~^ ]/g,
  alphanumeric: /[^0-9A-Za-záàâãéèêíìïóôõöúùçñÁÀÂÃÉÈÍÌÏÓÔÕÖÚÙÇÑ´`~^ ]/g,
  hex: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // phone: {
  //   "pt-BR": /\(?\b([0-9]{2}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/,
  //   "en-US": /^\d{3}-\d{3}-\d{4}$/,
  // },
  phone: /\(?\b([0-9]{2}|0((x|[0-9]){2,3}[0-9]{2}))\)?\s*[0-9]{4,5}[- ]*[0-9]{4}\b/,
  variable: /(\{\{[^}]*\}\}+(::[a-z-]*)*)/gm,
  variableModifier: /(::[a-z-]*)/gm,
  urlLink: /\[(.*?)\]\((.*?)\)/g,
  modalLink: /\[(.*?)\]<(.*?)>/g,
  nit: /[0-9]{3}\.?[0-9]{5}\.?[0-9]{2}-?[0-9]{1}/,
  licensePlate: /[A-Za-z]{3}[0-9][0-9A-Za-z][0-9]{2}/,
};

/** Collection of input masks */
export const Mask = {
  cep: "99.999-999",
  cnpj: "99.999.999/9999-99",
  cpf: "999.999.999-99",
  date: "99/99/9999",
  nit: "999.99999.99-9",
  licensePlate: "aaa9*99",
};

/** Filter null and undefined values in object
 *  https://stackoverflow.com/a/57625661/9629238
 */
export function cleanObject(obj) {
  return Object.entries(obj).reduce((a, [k, v]) => (v == null || v === "" ? a : ((a[k] = v), a)), {});
}

export function removeSpecialChars(str = "") {
  return str.replace(/[^a-zA-Z0-9 ]/g, "");
}

export function removeSpecialCharsAndNumbers(str = "") {
  return str.replace(/[^a-zA-Z ]/g, "");
}

export function replaceSpecialChars(str = "") {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/** Converts string to Title Case */
export function toTitleCase(str = "") {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/** Returns the first word of a string */
export function getFirstWord(str = "") {
  return str?.trim().split(" ")[0];
}

export function applyVariable(value, parseLinks, variables) {
  let newValue = value;

  let foundVariables = newValue.match(Regex.variable);

  foundVariables?.forEach((foundVariable) => {
    let cleanFoundVariable = cleanVariable(foundVariable);

    // Change the string for the variable value only if its a property (even if it is undefined or "")
    if (variables?.hasOwnProperty(cleanFoundVariable)) {
      let variableValue = variables[cleanFoundVariable] || "";

      if (variableValue) {
        // Tests for modifiers
        let modifiers = foundVariable.match(Regex.variableModifier);

        variableValue = applyModifiers(variableValue, modifiers);
      }

      newValue = newValue?.replaceAll(foundVariable, variableValue);
    }
  });

  if (parseLinks) {
    // Checks whether the string has link syntax
    const valueContainsLink = newValue.match(Regex.urlLink);

    // For each link found in the string, exchange the link syntax for <a />
    valueContainsLink?.forEach((_) => {
      let linkFormatted;

      linkFormatted = replaceVariables(newValue, /\[(.*?)\)/, (match, i) => {
        const matchSplitted = match?.split("]("); // Ex: => ['text', 'link']
        const linkText = matchSplitted[0];
        const linkHref = matchSplitted[1];

        return (
          <a
            key={`LINK_${i}`}
            className="underline text-secondary"
            {...{
              href: linkHref.replace(/[\u200B-\u200D\uFEFF]/g, ""),
              target: "_blank",
              rel: "noopener noreferrer",
            }}
          >
            {linkText}
          </a>
        );
      });

      newValue = linkFormatted;
    });
  }

  return newValue;
}
